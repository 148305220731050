import React, { InputHTMLAttributes } from 'react'
import classnames from 'classnames'
import { Button, ButtonProps } from './Button'
import styles from './Search.module.scss'
import { UIProps } from '../types'

export type SearchProps = UIProps &
  InputHTMLAttributes<HTMLInputElement> & {
    onSubmit: React.EventHandler<React.SyntheticEvent>
    size?: 'lg' | 'md' | 'sm'
    buttonProps?: ButtonProps
  }

export const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  ({ className, onSubmit, size = 'sm', buttonProps, ...rest }, inputRef) => {
    const c = classnames(styles.container, styles[size], className)

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmit(e)
      }
    }

    return (
      <div className={c} onKeyDown={onKeyDown}>
        <input className={styles.input} ref={inputRef} {...rest} />
        <Button className={styles.submit} onClick={onSubmit} {...buttonProps}>
          <MagnifyingGlass />
        </Button>
      </div>
    )
  }
)

// const QuestionMark = () => (
//   <div className={styles['question-mark']}>
//     <svg
//       width="6"
//       height="12"
//       viewBox="0 0 6 12"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.872 7.472H3.488C3.264 5.408 5.76 4.688 5.76 2.656C5.76 0.912001 4.56 0 2.896 0C1.696 0 0.704 0.576 0 1.392L1.024 2.336C1.488 1.824 2.016 1.504 2.672 1.504C3.488 1.504 3.968 2.016 3.968 2.784C3.968 4.224 1.536 5.136 1.872 7.472ZM2.688 11.104C3.36 11.104 3.888 10.576 3.888 9.856C3.888 9.136 3.36 8.608 2.688 8.608C2.016 8.608 1.504 9.136 1.504 9.856C1.504 10.576 2.016 11.104 2.688 11.104Z"
//         fill="#017ACC"
//       />
//     </svg>
//   </div>
// )

const MagnifyingGlass = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.67447 14.8196C11.6325 14.8196 14.86 11.7261 14.86 7.88472C14.86 4.04394 11.6324 0.950562 7.67447 0.950562C3.71599 0.950562 0.488281 4.04381 0.488281 7.88472C0.488281 11.7262 3.71586 14.8196 7.67447 14.8196ZM7.67447 3.23349C10.3927 3.23349 12.577 5.32696 12.577 7.88472C12.577 10.4431 10.3928 12.5366 7.67447 12.5366C4.95551 12.5366 2.77121 10.4432 2.77121 7.88472C2.77121 5.32688 4.9556 3.23349 7.67447 3.23349Z"
      fill="#017ACC"
    />
    <path
      d="M11.4454 13.3675L15.8513 17.5903C16.3065 18.0265 17.0291 18.0111 17.4653 17.556C17.9015 17.1009 17.8861 16.3783 17.431 15.9421L13.025 11.7194C12.5699 11.2831 11.8473 11.2985 11.4111 11.7536C10.9749 12.2088 10.9903 12.9313 11.4454 13.3675Z"
      fill="#017ACC"
    />
  </svg>
)
