import { FC, forwardRef } from 'react'
import classnames from 'classnames'
import { El, ElProps } from './El'
import styles from './Flex.module.scss'

/**
 * This is an experiment to replicate some css-in-js utilities for Next 13 in the style of styled-components or ChakraUI
 */

export type FlexProps = ElProps & {
  direction?: 'row' | 'column'
  justifyContent?:
  | 'start'
  | 'center'
  | 'end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  alignItems?: 'start' | 'center' | 'end' | 'stretch' | 'baseline'
  gap?: number
}

export const Flex: FC<FlexProps> = forwardRef<HTMLElement, FlexProps>(({
  className,
  direction = 'row',
  justifyContent,
  alignItems,
  gap,
  ...rest
}, ref) => {
  const c = classnames(
    styles.flex,
    styles[direction],
    justifyContent ? styles[`justify-content-${justifyContent}`] : undefined,
    alignItems ? styles[`align-items-${alignItems}`] : undefined,
    gap ? styles[`gap-${gap}`] : undefined,
    className
  )

  return <El ref={ref} className={c} {...rest} />
})
Flex.displayName = 'Flex'
