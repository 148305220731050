import React, {
  AnchorHTMLAttributes,
  ComponentClass,
  createElement,
  forwardRef,
  FunctionComponent, LegacyRef,
} from 'react'
import classnames from 'classnames'
import styles from './Link.module.scss'
import { ElProps, getElHelperStyles } from './El'

export type AnchorLinkProps = Omit<ElProps, 'as'> &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string // href is not required in AnchorHTMLAttributes. Require it here.
    active?: boolean
    focus?: boolean
    variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'unstyled'
  }

export const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
  (props, ref) => {
    const {
      className,
      variant = 'primary',
      // active,
      // focus,
      m,
      mx,
      my,
      mt,
      mr,
      mb,
      ml,
      p,
      px,
      py,
      pt,
      pr,
      pb,
      pl,
      children,
      ...rest
    } = props

    const c = classnames(
      styles.base,
      styles[variant],
      props.active ? styles.active : undefined,
      props.focus ? styles.focus : undefined,
      ...getElHelperStyles(props, styles),
      className
    )

    return (
      <a {...rest} className={c} ref={ref}>
        {children}
      </a>
    )
  }
)
AnchorLink.displayName = 'AnchorLink'

export type RouterLinkProps<AsType extends object> = Omit<ElProps, 'as'> &
  Omit<AsType, 'as'> & {
    as: FunctionComponent<AsType> | ComponentClass<AsType>
    active?: boolean
    focus?: boolean
    variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'unstyled'
  }

interface RouterLinkWithForwardRef
  extends React.FC<
    RouterLinkProps<object> & { ref?: LegacyRef<HTMLAnchorElement> }
  > {
  <AsType extends object>(props: RouterLinkProps<AsType>): ReturnType<
    React.FC<RouterLinkProps<AsType>>
  >
}

export const RouterLink: RouterLinkWithForwardRef = forwardRef((props, ref) => {
  const {
    as,
    className,
    variant = 'primary',
    // active,
    // focus,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    p,
    px,
    py,
    pt,
    pr,
    pb,
    pl,
    children,
    ...rest
  } = props

  const c = classnames(
    styles.base,
    styles[variant],
    props.active ? styles.active : undefined,
    props.focus ? styles.focus : undefined,
    ...getElHelperStyles(props, styles),
    className
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: fix type... omit ElProps from 'as' props??
  return createElement(as, { ...rest, ref, className: c }, children)
})
RouterLink.displayName = 'RouterLink'

// export type LinkProps<E extends object> = AnchorLinkProps | RouterLinkProps<E>
//
// interface LinkWithForwardRef
//   extends React.FC<LinkProps<object> & { ref?: React.Ref<HTMLAnchorElement> }> {
//   <AsType extends object>(props: LinkProps<AsType>): ReturnType<
//     React.FC<LinkProps<AsType>>
//   >
// }
//
// export const Link: LinkWithForwardRef = forwardRef((props, ref) => {
//   if ('as' in props) {
//     return <RouterLink {...props} ref={ref} />
//   }
//
//   return <AnchorLink {...props} ref={ref} />
// })
//
// export type AnchorProps = ElProps &
//   AnchorHTMLAttributes<HTMLAnchorElement> & {
//     active?: boolean
//     focus?: boolean
//   }

/**
 * An unstyled link for use within text or a heading external to react-router. (a basic <a> tag)
 */
// export const A = (props: AnchorProps) => <El as="a" {...props} />
