import { UIProps } from '../types'
import {
  // ComponentType,
  createElement,
  // FC,
  forwardRef,
  // ForwardRefExoticComponent,
  HTMLAttributes,
} from 'react'
import classnames from 'classnames'
import styles from './El.module.scss'

/**
 * This is an experiment to replicate some css-in-js utilities for Next 13 in the style of styled-components or ChakraUI
 */

// TODO: figure out how to map HTML attributes of element from 'as' prop to type
// export type ElProps<E extends keyof JSX.IntrinsicElements> =
//     UIProps & {
//         as?: E
//     } &
//     HTMLAttributes<E>

export type ElProps = UIProps &
SpacingProps & {
  as?: keyof JSX.IntrinsicElements
  'data-test'?: string
} & HTMLAttributes<HTMLElement>

export type SpacingProps = {
  m?: number
  mx?: number
  my?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  p?: number
  px?: number
  py?: number
  pt?: number
  pr?: number
  pb?: number
  pl?: number
}

export function getElHelperStyles(
  props: Omit<ElProps, 'as'>,
  elStyles: Readonly<Record<string, string>>
): (string | undefined)[] {
  const { m, mx, my, mt, mr, mb, ml, p, px, py, pt, pr, pb, pl } = props
  return [
    typeof m === 'number' ? elStyles[`m-${m}`] : undefined,
    typeof mx === 'number' ? elStyles[`mx-${mx}`] : undefined,
    typeof my === 'number' ? elStyles[`my-${my}`] : undefined,
    typeof mt === 'number' ? elStyles[`mt-${mt}`] : undefined,
    typeof mr === 'number' ? elStyles[`mr-${mr}`] : undefined,
    typeof mb === 'number' ? elStyles[`mb-${mb}`] : undefined,
    typeof ml === 'number' ? elStyles[`ml-${ml}`] : undefined,
    typeof p === 'number' ? elStyles[`p-${p}`] : undefined,
    typeof px === 'number' ? elStyles[`px-${px}`] : undefined,
    typeof py === 'number' ? elStyles[`py-${py}`] : undefined,
    typeof pt === 'number' ? elStyles[`pt-${pt}`] : undefined,
    typeof pr === 'number' ? elStyles[`pr-${pr}`] : undefined,
    typeof pb === 'number' ? elStyles[`pb-${pb}`] : undefined,
    typeof pl === 'number' ? elStyles[`pl-${pl}`] : undefined,
  ]
}

export const El = forwardRef<HTMLElement, ElProps>((props, ref) => {
  const {
    as = 'div',
    className,
    children,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    p,
    px,
    py,
    pt,
    pr,
    pb,
    pl,
    ...rest
  } = props
  const c = classnames(
    styles.base,
    ...getElHelperStyles(props, styles),
    className
  )
  return createElement(as as string, { className: c, ref, ...rest }, children)
})

//
El.displayName = 'El'