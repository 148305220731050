import { FC } from 'react'
import classnames from 'classnames'
import s from './LoginBox.module.scss'
import { UIProps } from '../types'

interface Props {
  error?: boolean
}

export const LoginBox: FC<Props & UIProps> = (props) => {
  const { error, className, ...rest } = props
  const c = classnames(s.base, error ? s.error : undefined, className)

  return <div className={c} {...rest} />
}
