import { FC, forwardRef } from 'react'
import classnames from 'classnames'
import { El, ElProps } from './El'
import s from './Center.module.css'

/**
 * This is an experiment to replicate some css-in-js utilities for Next 13 in the style of styled-components or ChakraUI
 */
export const Center: FC<ElProps> = forwardRef<HTMLElement, ElProps>((props, ref) => {
  const { className, ...rest } = props
  return <El ref={ref} className={classnames(s.center, className)} {...rest} />
})
Center.displayName = 'Center'
