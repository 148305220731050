import classnames from 'classnames'

export function omit<T extends Record<string, unknown>, K extends keyof T>(
  object: T,
  keys: K[]
): Omit<T, K> {
  const omitted: Record<string, unknown> = {}
  Object.keys(object).forEach((key) => {
    if (!keys.includes(key as K)) {
      omitted[key] = object[key]
    }
  })
  return omitted as Omit<T, K>
}

export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const newObj = {} as Pick<T, K>
  for (const key of keys) {
    newObj[key] = obj[key]
  }
  return newObj
}

export const getVariantClass = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Record<string, any>,
  styles: Record<string, string>,
  variants: Record<string, unknown>
) => {
  const p = pick(props, Object.keys(variants))
  const t = Object.keys(p).filter((key) => !!p[key])
  // TODO: warn if variant name used that is not in the styles
  return classnames(t.map((val) => styles[val]))
}

export const omitVariants = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest: Record<string, any>,
  variants: Record<string, unknown>,
  variants2?: Record<string, unknown>
) => {
  if (!variants2) {
    return omit(rest, Object.keys(variants))
  }
  // TODO: better way to pass many variants
  return omit(rest, Object.keys(variants).concat(Object.keys(variants2)))
}

export const emailRegex =
  /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/ // eslint-disable-line

/**
 * Type guard to see if UIEvent is a touch event in TypeScript. Need to check
 * for window.TouchEvent to not throw error in desktop Safari & Firefox
 */
export const isTouchEvent = (
  e: Event | React.MouseEvent | React.TouchEvent,
): e is React.TouchEvent => {
  return 'touches' in e
}
