import React, {
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from 'react'
import classnames from 'classnames'
import styles from './Input.module.scss'
import { El, ElProps } from './El'

export type InputProps = ElProps &
  InputHTMLAttributes<HTMLInputElement> & {
    variant?: 'default' | 'filled'
    error?: boolean
    textAlign?: 'start' | 'center' | 'end'
  }

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant = 'default', className, error, textAlign, ...rest }, inputRef) => {
    const c = classnames(
      styles.input,
      styles[variant],
      error ? styles.error : undefined,
      textAlign ? styles[`text-align-${textAlign}`] : undefined,
      className
    )

    return <input className={c} ref={inputRef} {...rest} />
  }
)

export type InputLabelProps = ElProps &
  LabelHTMLAttributes<HTMLLabelElement> & {
    required?: boolean
    error?: boolean
  }

export const InputLabel: FC<InputLabelProps> = ({
  required,
  className,
  children,
  error,
  ...rest
}) => {
  const c = classnames(
    styles.inputLabel,
    error ? styles.errorLabel : undefined,
    className
  )

  return (
    <El className={c} as="label" {...rest}>
      {children}
      {required && '*'}
    </El>
  )
}

export type InputHelperProps = ElProps & HTMLAttributes<HTMLSpanElement>

export const InputHelper: FC<InputHelperProps> = ({ className, ...rest }) => {
  const c = classnames(styles.inputHelper, className)

  return <El className={c} as="div" {...rest} />
}

// by aria standards, we want the helper text to be a valid element
// https://www.w3.org/WAI/tutorials/forms/instructions/#in-line-instructions
//
// <form>
//   <label htmlFor="input">Label Text</label>
//   <span id="instructions">Additional instructions lorem ipsum</span>
//   <input id="input" aria-describedby="instructions" type="text">
// </form>
