import { forwardRef, HTMLAttributes } from 'react'
import styles from './Text.module.scss'
import classnames from 'classnames'
import { El, ElProps } from './El'

export type TextProps = ElProps &
HTMLAttributes<HTMLHeadingElement> & {
  as?: 'p' | 'div' | 'span' | 'i' | 'mark' | 'del' | 'ins' // TODO: check on & include any valid text tag
  variant?: 'large' | 'medium' | 'small' | 'tag'
  margin?: boolean
  color?: 'primary' | 'success' | 'warning' | 'error'
  fontWeight?: number
  textAlign?: 'start' | 'center' | 'end'
  lineClamp?: 1 | 2 | 3
}

export const Text = forwardRef<HTMLElement, TextProps>((props, ref) => {
  const {
    as = 'span',
    variant = 'medium',
    margin,
    color,
    fontWeight,
    textAlign,
    lineClamp,
    className,
    ...rest
  } = props

  const c = classnames(
    styles[variant],
    !margin ? styles['noMargin'] : undefined,
    color ? styles[color] : undefined,
    fontWeight ? styles[`font-weight-${fontWeight}`] : undefined,
    textAlign ? styles[`text-align-${textAlign}`] : undefined,
    lineClamp ? styles[`line-clamp-${lineClamp}`] : undefined,
    className
  )

  return <El as={as} className={c} {...rest} ref={ref} />
})
Text.displayName = 'Text'
