import React from 'react'
import c from 'classnames'
import styles from './Spinner.module.scss'

export const Spinner = ({
  className
}: {
  className?: string
}) => {
  return (
    <div className={c(styles.spinnerContainer, className)}>
      <div className={styles.spinnerLayer}>
        <div className={styles.circleClipper}>
          <div className={styles.circleLeft} />
        </div>
        <div className={styles.gapPatch}>
          <div className={styles.gapCircle} />
        </div>
        <div className={styles.circleClipper}>
          <div className={styles.circleRight} />
        </div>
      </div>
    </div>
  )
}
