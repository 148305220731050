import React from 'react'
import classnames from 'classnames'
import { Button } from './Button'
import { ElProps } from './El'
import { Flex } from './Flex'
import styles from './Alert.module.scss'

type BaseProps = ElProps & {
  variant?: 'info' | 'success' | 'warning' | 'danger'
  handleClose?: () => void
}

export type AlertProps = BaseProps

export const Alert = ({
  className,
  variant = 'info',
  handleClose,
  children,
  ...rest
}: BaseProps) => {
  const c = classnames(styles.base, styles[variant], className)

  return (
    <Flex
      className={c}
      gap={3}
      alignItems="center"
      pr={!handleClose ? 3 : undefined}
      {...rest}
    >
      <Flex
        className={classnames(styles.icon, styles[`icon-${variant}`])}
        justifyContent="center"
        alignItems="center"
      >
        {variant === 'info' && <InfoIcon />}
        {variant === 'success' && <SuccessIcon />}
        {(variant === 'warning' || variant === 'danger') && <WarningIcon />}
      </Flex>
      {children}
      {handleClose && (
        <Button variant="unstyled" px={3} onClick={handleClose} data-test="alert-close-button">
          <CloseIcon />
        </Button>
      )}
    </Flex>
  )
}

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.87905 4.87021C8.64738 5.06568 8.35296 5.16342 7.99579 5.16342C7.62898 5.16342 7.33214 5.06568 7.1053 4.87021C6.87845 4.67474 6.76502 4.42245 6.76502 4.11334C6.76502 3.80423 6.87845 3.55421 7.1053 3.36329C7.33214 3.17237 7.62898 3.0769 7.99579 3.0769C8.35296 3.0769 8.64738 3.17237 8.87905 3.36329C9.11073 3.55421 9.22656 3.80423 9.22656 4.11334C9.22656 4.42245 9.11073 4.67474 8.87905 4.87021ZM7.99043 6.15894C8.5782 6.15894 9.05469 6.63542 9.05469 7.22319V11.8588C9.05469 12.4466 8.5782 12.9231 7.99043 12.9231C7.40266 12.9231 6.92618 12.4466 6.92618 11.8588V7.22319C6.92618 6.63542 7.40266 6.15894 7.99043 6.15894Z"
      fill="white"
    />
    <circle cx="8" cy="8" r="7.5" stroke="white" />
  </svg>
)

const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7669 2.59174C13.3145 2.2749 12.691 2.38479 12.3742 2.83719L6.69025 10.9543L3.33952 8.60785C2.88713 8.29105 2.26353 8.40093 1.94668 8.85328L1.84242 9.00212C1.52557 9.45446 1.63544 10.078 2.08782 10.3948L6.4068 13.4193C6.85918 13.7361 7.48278 13.6262 7.79964 13.1739L7.90389 13.0251C7.90747 13.0199 7.91099 13.0148 7.91446 13.0097L14.1612 4.08881C14.478 3.63641 14.3681 3.01283 13.9157 2.69599L13.7669 2.59174Z"
      fill="white"
    />
  </svg>
)

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.53597 14.8938H16.464C16.8641 14.8938 17.1224 14.5233 16.9409 14.2109L9.4769 1.36226C9.27856 1.02085 8.72195 1.02085 8.52362 1.36226L1.05904 14.2109C0.877571 14.5233 1.13639 14.8938 1.53597 14.8938Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43129 10.4212L8.21493 4.82187H9.77718L9.56081 10.4212H8.43129ZM8.09404 12.1153C8.09404 11.5628 8.46628 11.1783 8.99498 11.1783C9.5481 11.1783 9.8965 11.5628 9.90813 12.1153C9.90813 12.6562 9.5481 13.0529 8.99498 13.0529C8.45407 13.0529 8.08183 12.6562 8.09404 12.1153Z"
      fill="white"
    />
  </svg>
)

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M1.21484 1.22876L8.73371 8.73333"
      stroke="#4A4A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.26622 8.73336L8.73288 1.26669"
      stroke="#4A4A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
