import React, { FC, TextareaHTMLAttributes } from 'react'
import classnames from 'classnames'
import inputStyles from './Input.module.scss'
import styles from './Textarea.module.scss'
import { ElProps } from './El'

export type TextareaProps = Omit<ElProps, 'as'> &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    variant?: 'default' | 'filled'
    resize?: 'auto' | 'both' | 'horizontal' | 'vertical' | 'none'
    error?: boolean
    textAlign?: 'start' | 'center' | 'end'
  }

export const Textarea: FC<TextareaProps> = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(
  (
    {
      variant = 'default',
      resize = 'none',
      className,
      error,
      textAlign,
      ...rest
    },
    textareaRef
  ) => {
    const c = classnames(
      inputStyles.input,
      styles.textarea,
      inputStyles[variant],
      styles[`resize-${resize}`],
      error ? inputStyles.error : undefined,
      textAlign ? inputStyles[`text-align-${textAlign}`] : undefined,
      className
    )

    return <textarea className={c} ref={textareaRef} {...rest} />
  }
)
