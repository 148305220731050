import { FC, HTMLAttributes } from 'react'
import classnames from 'classnames'
import styles from './Heading.module.scss'
import { El, ElProps } from './El'
import { TextProps } from './Text'

export type HeadingProps = Omit<ElProps, 'as'> & HTMLAttributes<HTMLHeadingElement> & {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span'
  variant?: 'mega' | 'jumbo' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  visuallyHidden?: boolean
} & Pick<
    TextProps,
    'margin' | 'fontWeight' | 'textAlign' | 'lineClamp' | 'color'
  >

export const Heading: FC<HeadingProps> = (props) => {
  const {
    as = 'h2',
    variant = 'h2',
    className,
    margin = true,
    color,
    fontWeight,
    textAlign,
    lineClamp,
    visuallyHidden,
    ...rest
  } = props
  const c = classnames(
    styles.base,
    styles[variant],
    !margin ? styles.noMargin : undefined,
    color ? styles[color] : undefined,
    fontWeight ? styles[`font-weight-${fontWeight}`] : undefined,
    textAlign ? styles[`text-align-${textAlign}`] : undefined,
    lineClamp ? styles[`line-clamp-${lineClamp}`] : undefined,
    visuallyHidden ? styles['visuallyHidden'] : undefined,
    className
  )

  return <El as={as} className={c} {...rest} />
}
Heading.displayName = 'Heading'

// aliases
type AliasProps = Omit<HeadingProps, 'variant'>
export const Mega: FC<AliasProps> = (props) => {
  return <Heading variant="mega" as="h1" {...props} />
}
export const Jumbo: FC<AliasProps> = (props) => {
  return <Heading variant="jumbo" as="h1" {...props} />
}
export const H1: FC<AliasProps> = (props) => {
  return <Heading variant="h1" as="h1" {...props} />
}
export const H2: FC<AliasProps> = (props) => {
  return <Heading variant="h2" as="h2" {...props} />
}
export const H3: FC<AliasProps> = (props) => {
  return <Heading variant="h3" as="h3" {...props} />
}
export const H4: FC<AliasProps> = (props) => {
  return <Heading variant="h4" as="h4" {...props} />
}
export const H5: FC<AliasProps> = (props) => {
  return <Heading variant="h5" as="h5" {...props} />
}
