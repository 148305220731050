import { FC } from 'react'
import c from 'classnames'
import { El, ElProps } from './El'
import styles from './List.module.scss'

export const List: FC<ElProps> = ({ children, className, ...rest }) => (
  <El as="ul" {...rest} className={c(styles.list, className)}>{children}</El>
)

export const ListItem: FC<ElProps> = ({ children, className, ...rest }) => (
  <El as="li" {...rest} className={c(styles.item, className)}>{children}</El>
)
